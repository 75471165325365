import axios from 'axios'

const API_URL = 'https://desk.aixiao.xyz'

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async generateImage(prompt) {
    try {
      const response = await api.post('/generate', { prompt })
      return response.data
    } catch (error) {
      console.error('Error generating image:', error)
      throw error
    }
  },

  async getGalleryImages() {
    try {
      const response = await api.get('/api/gallery')
      console.log('API response:', response.data)  // Add this line for debugging
      return response.data
    } catch (error) {
      console.error('Error fetching gallery images:', error)
      throw error
    }
  }
}