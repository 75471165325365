<template>
  <div class="about bg-gradient-to-br from-purple-50 to-indigo-100">
    <div class="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <h1 class="text-4xl font-extrabold text-gray-900 sm:text-5xl text-center mb-12">
        <span class="block text-purple-600">关于 AI 图像生成器</span>
      </h1>
      <div class="mt-6 prose prose-lg text-gray-500 mx-auto custom-content">
      <div class="conclusion-section">
        <p class="conclusion">
          无论您是寻找灵感的艺术家，需要独特视觉效果的营销人员，还是只想将自己的想法变为现实的普通人，AI 图像生成器都能帮助您利用 AI 的力量创造出令人惊叹的图像。我们的技术将自然语言处理与先进的图像生成算法相结合，将您的创意转化为现实。
        </p>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div class="feature-section">
            <h2>工作原理</h2>
            <ol class="process-list">
              <li><span class="step-number">1</span>您提供想要创建的图像的文字描述。</li>
              <li><span class="step-number">2</span>我们的 AI 处理您的描述，理解您所需的关键元素和风格。</li>
              <li><span class="step-number">3</span>AI 然后根据您的描述生成一张独特的图像。</li>
              <li><span class="step-number">4</span>您可以下载生成的图像并用于您的项目。</li>
            </ol>
          </div>
          <div class="feature-section">
            <h2>应用场景</h2>
            <ol class="application-list">
              <li><span class="step-number">1</span>游戏、电影或书籍的概念艺术</li>
              <li><span class="step-number">2</span>文章或演示文稿的定制插图</li>
              <li><span class="step-number">3</span>营销材料的独特设计</li>
              <li><span class="step-number">4</span>家居装饰的个性化艺术品</li>
            </ol>
          </div>
        </div>
        <div class="feature-section mt-8">
          <h2>我们的技术</h2>
          <p>
            我们使用最先进的机器学习模型，包括用于文本理解的 GPT-3 和用于图像生成的 DALL-E。这种组合使我们能够创建与您的描述高度匹配的详细且准确的图像。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped>
.custom-content p {
  text-indent: 2em;
  line-height: 1.8;
}

.custom-content h2 {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1em;
  color: #4a5568;
  font-size: 1.75rem;
}

.lead-paragraph {
  font-size: 1.25rem;
  color: #4a5568;
  margin-bottom: 2em;
  text-indent: 0 !important;
}

.feature-section {
  background-color: white;
  border-radius: 8px;
  padding: 2em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-section:hover {
  transform: translateY(-5px);
}

.process-list, .application-list {
  list-style-type: none;
  padding-left: 0;
}

.process-list li, .application-list li {
  margin-bottom: 1em;
  padding-left: 3em;
  position: relative;
}

.step-number {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #6b46c1;
  color: white;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.conclusion-section {
  background-color: #6b46c1;
  border-radius: 8px;
  padding: 2em;
  margin-top: 2em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.conclusion {
  font-style: italic;
  color: white;
  text-indent: 0 !important;
  margin: 0;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.feature-section, .conclusion-section {
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.feature-section:nth-child(1) { animation-delay: 0.1s; }
.feature-section:nth-child(2) { animation-delay: 0.3s; }
.feature-section:nth-child(3) { animation-delay: 0.5s; }
.conclusion-section { animation-delay: 0.7s; }
</style>