import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    generatedImages: []
  },
  mutations: {
    addGeneratedImage(state, image) {
      state.generatedImages.unshift(image)
    }
  },
  actions: {
    saveGeneratedImage({ commit }, image) {
      commit('addGeneratedImage', image)
    }
  },
  getters: {
    getGeneratedImages: state => state.generatedImages
  }
})