<template>
  <div id="app">
    <router-view></router-view>
    <FloatingMenu ref="floatingMenu" />
  </div>
</template>

<script>
import FloatingMenu from '@/components/FloatingMenu.vue'

export default {
  name: 'App',
  components: {
    FloatingMenu
  },
  mounted() {
    this.$root.$on('setFloatingMenuState', (state) => {
      this.$refs.floatingMenu.setMenuState(state);
    });
  },
  beforeDestroy() {
    this.$root.$off('setFloatingMenuState');
  }
}
</script>

<style>
#app {
  font-family: 'PingFang SC', 'Helvetica Neue', Helvetica, 'Microsoft Yahei', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a5568;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* 添加一些全局的动画类 */
.animate-text {
  animation: colorChange 5s infinite;
}

@keyframes colorChange {
  0% { color: #6B46C1; }
  50% { color: #4299E1; }
  100% { color: #6B46C1; }
}

.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}
</style>