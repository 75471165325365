<template>
  <div class="gallery bg-gradient-to-r from-indigo-50 to-purple-100 min-h-screen">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h1 class="text-4xl font-extrabold text-gray-900 sm:text-5xl text-center mb-12">
        <span class="block text-purple-600">AI 图像画廊</span>
      </h1>
      <div v-if="loading" class="text-center">
        <p class="text-xl text-gray-600">正在加载图片...</p>
      </div>
      <div v-else-if="error" class="text-center">
        <p class="text-xl text-red-600">{{ error }}</p>
      </div>
      <div v-else-if="images.length === 0" class="text-center">
        <p class="text-xl text-gray-600">暂无图片</p>
      </div>
      <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div v-for="image in images" :key="image._id" class="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300">
          <img :src="image.url" :alt="image.prompt" class="w-full h-64 object-cover cursor-pointer" @click="openModal(image)">
          <div class="p-4">
            <p class="text-sm text-gray-600 truncate">{{ image.prompt }}</p>
            <div class="mt-4 flex justify-between">
              <button @click="openModal(image)" class="text-purple-600 hover:text-purple-800 font-medium">
                查看详情
              </button>
              <a :href="image.url" download class="text-green-600 hover:text-green-800 font-medium">
                下载
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="selectedImage" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div class="bg-white rounded-lg max-w-3xl w-full p-6 relative">
        <button @click="closeModal" class="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <img :src="selectedImage.url" :alt="selectedImage.prompt" class="w-full rounded-lg shadow-lg mb-4">
        <h3 class="text-lg font-semibold mb-2">生成提示词：</h3>
        <p class="text-gray-700 mb-4">{{ selectedImage.prompt }}</p>
        <div class="flex justify-end">
          <a :href="selectedImage.url" download class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200">
            下载图像
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import api from '@/services/api'

export default {
  name: 'Gallery',
  setup() {
    const images = ref([])
    const selectedImage = ref(null)
    const loading = ref(true)
    const error = ref(null)

    const fetchImages = async () => {
      try {
        loading.value = true
        error.value = null
        const response = await api.getGalleryImages()
        console.log('API response:', response)  // Add this line for debugging
        images.value = response
      } catch (err) {
        console.error('Failed to fetch images:', err)
        error.value = '加载图片失败，请稍后重试。'
      } finally {
        loading.value = false
      }
    }

    const openModal = (image) => {
      selectedImage.value = image
    }

    const closeModal = () => {
      selectedImage.value = null
    }

    onMounted(fetchImages)

    return {
      images,
      selectedImage,
      loading,
      error,
      openModal,
      closeModal
    }
  }
}
</script>