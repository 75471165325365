<template>
  <div class="home">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="text-center sm:text-left">
        <div class="flex items-center justify-center sm:justify-start mb-2">
          <img src="@/assets/logo.svg" alt="AI 图像生成器 Logo" class="w-12 h-12 mr-4">
          <span class="text-3xl sm:text-4xl font-extrabold text-gray-900">欢迎使用</span>
        </div>
        <h1 class="text-4xl sm:text-5xl md:text-6xl font-extrabold">
          <span class="block text-purple-600 animate-text">AI 图像生成器</span>
        </h1>
      </div>
      <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl sm:text-left">
        利用 AI 的力量创造令人惊叹的图像。只需描述您想看到的内容，就能见证奇迹发生！
      </p>
      <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-start md:mt-8">
        <div class="rounded-md shadow">
          <router-link to="/generate" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 md:py-4 md:text-lg md:px-10">
            开始创作
          </router-link>
        </div>
        <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
          <router-link to="/gallery" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
            浏览图库
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
@keyframes colorChange {
  0% { color: #6B46C1; }
  50% { color: #4299E1; }
  100% { color: #6B46C1; }
}

.animate-text {
  animation: colorChange 5s infinite;
}

@media (max-width: 640px) {
  .home h1 {
    font-size: 2.5rem;
  }
}
</style>