<template>
  <div class="generate bg-gradient-to-r from-purple-50 to-indigo-100 min-h-screen">
    <div class="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h1 class="text-4xl font-extrabold text-gray-900 sm:text-5xl text-center mb-8">
        <span class="block text-purple-600">AI 图像生成器</span>
      </h1>
      <div class="bg-white shadow-xl rounded-lg overflow-hidden">
        <div class="px-6 py-8">
          <div class="mb-6">
            <label for="prompt" class="block text-sm font-medium text-gray-700 mb-2">输入您的描述</label>
            <textarea
              id="prompt"
              v-model="prompt"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
              @keyup.enter="generateImage"
              class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-purple-500"
              rows="3"
              placeholder="例如：一只在月光下嚎叫的狼"
            ></textarea>
          </div>
          <div class="mb-6">
            <p class="text-sm font-medium text-gray-700 mb-2">推荐提示词：</p>
            <div class="flex flex-wrap gap-2">
              <button
                v-for="(suggestion, index) in promptSuggestions"
                :key="index"
                @click="setPrompt(suggestion)"
                class="px-3 py-1 bg-purple-100 text-purple-700 rounded-full text-sm hover:bg-purple-200 transition-colors duration-200"
              >
                {{ suggestion }}
              </button>
            </div>
          </div>
          <button
            @click="generateImage"
            :disabled="isGenerating"
            class="w-full bg-purple-600 text-white py-3 px-4 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
          >
            <span v-if="isGenerating" class="inline-block animate-spin mr-2">🔄</span>
            {{ isGenerating ? '正在生成...' : '生成图像' }}
          </button>
        </div>
        <div v-if="generatedImage" class="px-6 py-8 border-t border-gray-200">
          <img :src="generatedImage.url" alt="生成的图像" class="w-full rounded-lg shadow-lg mb-4">
          <div class="flex justify-center">
            <a
              :href="generatedImage.url"
              download
              class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
            >
              下载图像
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'Generate',
  data() {
    return {
      prompt: '',
      generatedImage: null,
      isGenerating: false,
      promptSuggestions: [
        "一只在月光下嚎叫的狼",
        "繁华都市中的未来机器人",
        "深海中的神秘生物",
        "古老图书馆里的魔法书",
        "飞翔在彩虹上的独角兽"
      ]
    }
  },
  methods: {
    async generateImage() {
      if (!this.prompt) return
      this.isGenerating = true
      try {
        const result = await api.generateImage(this.prompt)
        this.generatedImage = result
      } catch (error) {
        console.error('生成图像失败:', error)
        // 处理错误（例如，显示错误消息）
      } finally {
        this.isGenerating = false
      }
    },
    setPrompt(suggestion) {
      this.prompt = suggestion
    },
    handleInputFocus() {
      this.$root.$emit('setFloatingMenuState', false);
    },
    handleInputBlur() {
      this.$root.$emit('setFloatingMenuState', true);
    }
  }
}
</script>