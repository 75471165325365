<template>
  <div class="floating-menu" :class="{ 'is-open': isOpen }" :style="{ transform: `rotate(${rotation}deg)` }">
    <div class="menu-ring" @mousedown="startDrag" @touchstart="startDrag">
      <div class="drag-layer"></div>
      <button @click="toggleMenu" class="menu-button main-button" @touchend="handleButtonTouch">
        <i class="fas" :class="isOpen ? 'fa-times' : 'fa-bars'"></i>
      </button>
      <router-link v-for="(item, index) in menuItems" :key="item.path" :to="item.path" 
                   class="menu-item" 
                   :style="getItemStyle(index)"
                   @click.native="navigateTo(item.path)"
                   @touchend.prevent="handleButtonTouch($event, item.path)">
        <i :class="item.icon"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloatingMenu',
  data() {
    return {
      isOpen: true,
      rotation: 0,
      startAngle: 0,
      center: { x: 0, y: 0 },
      isDragging: false,
      menuItems: [
        { name: '首页', path: '/', icon: 'fas fa-home' },
        { name: '生成图像', path: '/generate', icon: 'fas fa-plus-circle' },
        { name: '图库', path: '/gallery', icon: 'fas fa-images' },
        { name: '关于我们', path: '/about', icon: 'fas fa-info-circle' }
      ]
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    getItemStyle(index) {
      const angle = (index / this.menuItems.length) * 2 * Math.PI;
      const radius = 80;
      return {
        transform: `translate(${Math.cos(angle) * radius}px, ${Math.sin(angle) * radius}px)`
      }
    },
    startDrag(event) {
      if (event.target.closest('.menu-button, .menu-item')) return;
      event.preventDefault();
      this.isDragging = true;
      const rect = this.$el.getBoundingClientRect();
      this.center = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2
      };
      this.startAngle = this.getAngle(event);
      
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('touchmove', this.drag);
      document.addEventListener('mouseup', this.stopDrag);
      document.addEventListener('touchend', this.stopDrag);
    },
    getAngle(event) {
      const clientX = event.clientX || (event.touches && event.touches[0].clientX);
      const clientY = event.clientY || (event.touches && event.touches[0].clientY);
      const x = clientX - this.center.x;
      const y = clientY - this.center.y;
      return Math.atan2(y, x);
    },
    drag(event) {
      if (!this.isDragging) return;
      const currentAngle = this.getAngle(event);
      let deltaAngle = currentAngle - this.startAngle;
      if (deltaAngle > Math.PI) deltaAngle -= 2 * Math.PI;
      if (deltaAngle < -Math.PI) deltaAngle += 2 * Math.PI;
      this.rotation += deltaAngle * (180 / Math.PI);
      this.startAngle = currentAngle;
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('touchmove', this.drag);
      document.removeEventListener('mouseup', this.stopDrag);
      document.removeEventListener('touchend', this.stopDrag);
    },
    navigateTo(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    handleButtonTouch(event, path) {
      event.preventDefault();
      if (path) {
        this.navigateTo(path);
      } else {
        this.toggleMenu();
      }
    },
    setMenuState(state) {
      this.isOpen = state;
    }
  }
}
</script>

<style scoped>
.floating-menu {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.menu-ring {
  position: relative;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background-color: rgba(107, 70, 193, 0.1);
  box-shadow: 0 0 0 10px rgba(107, 70, 193, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.drag-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  cursor: move;
}

.menu-button, .menu-item {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #6B46C1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
}

.main-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-item {
  width: 50px;
  height: 50px;
  background-color: #9F7AEA;
}

.menu-item:hover {
  background-color: #805AD5;
  transform: scale(1.1);
}

.floating-menu:not(.is-open) .menu-ring {
  width: 80px;
  height: 80px;
  background-color: rgba(107, 70, 193, 0.05);
  box-shadow: 0 0 0 5px rgba(107, 70, 193, 0.02);
}

.floating-menu:not(.is-open) .menu-item {
  opacity: 0;
  pointer-events: none;
}

.floating-menu.is-open .menu-item {
  opacity: 1;
  pointer-events: auto;
}
</style>